@import-normalize;

body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
p,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

/* Regular */

@font-face {
    font-family: "Inter";
    src: local("Inter Regular"), local("Inter-Regular"), url("./assets/fonts/Inter-Regular.woff2") format("woff2"),
        url("./assets/fonts/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* Bold */

@font-face {
    font-family: "Inter";
    src: local("Inter Bold"), local("Inter-Bold"), url("./assets/fonts/Inter-Bold.woff2") format("woff2"),
        url("./assets/fonts/Inter-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

/* Extra Bold */

@font-face {
    font-family: "Inter";
    src: local("Inter Extra Bold"), local("Inter-ExtraBold"), url("./assets/fonts/Inter-ExtraBold.woff2") format("woff2"),
        url("./assets/fonts/Inter-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

